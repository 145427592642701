import React from 'react'
import { NavPages } from '../components/NavPages'
import { Footer } from '../components/Footer'

const Cookie = () => {

  return (
    <>
      <NavPages />
      <div className="container">
        <div className="row">
          <div className="col-lg-9 ma">
            <div className="s-100"></div>
            <h3 className='title c-dark'>Політика використання файлів cookie</h3>
            <div className="s-40"></div>
            <p className='c-dark'>Ми використовуємо "cookies" (файли cookie) для збору інформації про вас та вашу активність на нашому сайті. Файл cookie — це невеликий фрагмент даних, який наш вебсайт зберігає на вашому комп'ютері і до якого звертається кожного разу при вашому відвідуванні, щоб ми могли зрозуміти, як ви використовуєте наш сайт, та надавати вам контент відповідно до ваших налаштувань.</p>

            <p className='c-dark'>Якщо ви не бажаєте приймати файли cookie від нас, ви повинні налаштувати свій браузер на відмову від файлів cookie з нашого вебсайту, розуміючи, що ми можемо бути не в змозі надати вам деякі з потрібних вам послуг без них. Ця політика стосується тільки використання файлів cookie між вашим комп'ютером та нашим вебсайтом; вона не охоплює використання файлів cookie будь-якими сторонніми службами, які ми використовуємо на нашому сайті.</p>

            <h4 className='c-dark py-3'>Доступ третіх сторін до інформації</h4>
            <p className='c-dark'>Ми можемо використовувати сторонні служби для нашого вебсайту та маркетингової діяльності. Ці служби можуть мати доступ до наших даних виключно з метою виконання певних завдань від нашого імені. Ми не ділимося жодною особисто ідентифікованою інформацією з цими службами без вашої виразної згоди. Ми не надаємо цим службам дозволу розкривати або використовувати будь-які наші дані з будь-якою іншою метою.</p>

            <p className='c-dark'>Ми відмовляємось від урядових та запитів правоохоронних органів на дані, якщо вважаємо запит занадто широким або не пов'язаним з його заявленою метою. Однак ми можемо співпрацювати, якщо вважаємо, що запитувана інформація необхідна та відповідна для виконання юридичного процесу;</p>

            <p className='c-dark'>Для захисту наших власних прав та власності;</p>
            <p className='c-dark'>Для захисту безпеки громадськості та будь-якої особи;</p>
            <p className='c-dark'>Для запобігання злочину;</p>
            <p className='c-dark'>Для запобігання тому, що ми розумно вважаємо незаконним;</p>
            <p className='c-dark'>Для запобігання тому, що ми розумно вважаємо законно піддати судовому переслідуванню а бо неетичною діяльністю;</p>
            <p className='c-dark'>Ми інакше не ділимося або не передаємо особисту інформацію третім сторонам;</p>
            <p className='c-dark'>Ми не продаємо або не здаємо в оренду вашу особисту інформацію маркетологам або третім сторонам.</p>
            <h4 className='c-dark py-3'>Конфіденційність дітей</h4>
            <p className='c-dark'>Цей вебсайт не цілеспрямовано збирає або збирає особисту інформацію від дітей. Як батько/опікун, будь ласка, звертайтеся до нас, якщо ви вважаєте, що ваша дитина бере участь у діяльності, що включає особисту інформацію на нашому вебсайті, і ви не отримали повідомлення або запит на згоду. Ми не використовуємо надані вами контактні дані для маркетингових або рекламних цілей.</p>
            <h4 className='c-dark py-3'>Межі нашої політики</h4>
            <p className='c-dark'>Ця політика конфіденційності охоплює лише збір та обробку даних компанією IT-Виконороб. Ми співпрацюємо тільки з партнерами, афілійованими особами та сторонніми постачальниками, чиї політики конфіденційності відповідають нашим, однак ми не можемо нести відповідальність або відповідальність за їхні відповідні практики конфіденційності.</p>
            <p className='c-dark'>Наш вебсайт може містити посилання на зовнішні сайти, які не керуються нами. Будь ласка, майте на увазі, що ми не контролюємо контент і політики цих сайтів, і не можемо нести відповідальність за їхні відповідні практики конфіденційності.</p>
            <h4 className='c-dark py-3'>Зміни цієї політики</h4>
            <p className='c-dark'>За нашим розсудом ми можемо оновити цю політику, щоб відобразити поточні прийнятні практики. Ми вживемо розумних заходів, щоб повідомити користувачів про зміни через наш вебсайт. Ваше продовження використання цього сайту після будь-яких змін до цієї політики буде вважатися прийняттям наших практик щодо даних і особистої інформації.</p>
            <h4 className='c-dark py-3'>Ваші права та обов'язки</h4>
            <p className='c-dark'>Як наш користувач, у вас є право бути проінформованим про те, як ваші дані збираються та використовуються. Ви маєте право знати, які дані ми збираємо про вас, і як вони обробляються</p>
            <p className='c-dark'>Ви маєте право виправити та оновити будь-яку особисту інформацію про вас та запросити видалення цієї інформації.</p>
            <p className='c-dark'>Ви маєте право обмежити або заперечувати проти нашого використання вашої інформації, зберігаючи право використовувати вашу особисту інформацію для ваших власних цілей. Ви маєте право відмовитися від використання даних про вас у рішеннях, що базуються виключно на автоматизованій обробці.</p>
            <p className='c-dark'>Будь ласка, звертайтеся до нас, якщо у вас виникнуть питання або занепокоєння щодо того, як ми обробляємо ваші дані та особисту інформацію.</p>
            <div className="s-100"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export { Cookie }
