import React from 'react';

import pic from '../../assets/Irina.png';

const Intro = () => {
  return (
    <section id='top' className='h-80 flex jcc aic'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 flex jcc aic h-50 p-4'>
            <img
              src={pic}
              alt='IT-Виконороб'
              width={'100%'}
              height={'100%'}
              className='obfc'
            />
          </div>
          <div className='col-lg-7 h-60 flex jcc aic'>
            <div>
              <h1 className='title-intro'>
                ВАШЕ РІШЕННЯ
                <br />
                ДЛЯ <span className='c-theme'>ТАЛАНТІВ</span>
              </h1>
              <h2 className='title-intro-sub'>ДОСТАВКА ПО ВСЬОМУ СВІТУ</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Intro };
