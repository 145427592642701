import React from 'react'
import { NavPages } from '../components/NavPages'
import { Footer } from '../components/Footer'

const Privacy = () => {

  return (
    <>
      <NavPages />
        <div className="container">
          <div className="row">
            <div className="col-lg-9 ma">
              <div className="s-100"></div>
              <h3 className='title c-dark'>Політика конфіденційності</h3>
              <div className="s-40"></div>
              <h4 className='c-dark py-3'>1. Загальні положення</h4>
              <p className='c-dark'>Політика конфіденційності (далі "Політика") благодійної організації "IT-Виконороб" була розроблена відповідно до Закону України "Про захист персональних даних" та інших нормативних актів, які регулюють відносини у фідуціарній сфері.</p>
              <p className='c-dark'>Політика містить стандарти, які Фонд використовує для роботи з персональною інформацією відвідувачів Сайту (далі "Користувач"), яку Фонд може отримати через Сайт, розташований за адресою: https://vikonrob.co.ua/ (далі "Сайт"), та пояснює, як ви можете зв'язатися з Фондом у разі будь-яких питань або зауважень.</p>
              <p className='c-dark'>Користувач надає персональну інформацію Фонду через Сайт виключно добровільно і це є підтвердженням того, що Користувач ознайомлений з вмістом цієї Політики та Положенням про захист персональних даних і порядком їх обробки (далі "Положення") і дає свою згоду на збір, обробку, використання та/або передачу персональних даних Користувача Фондом.</p>
              <p className='c-dark'>Інформація та персональні дані збираються, обробляються та використовуються відповідно до вимог чинного законодавства України.</p>

              <h4 className='c-dark py-3'>2. Структура та зміст персональних даних Користувача</h4>
              <p className='c-dark'>Ми не збираємо персональні дані Користувача через Сайт тільки у випадку, якщо Користувач добровільно надав відповідну інформацію та виразив згоду на її збір, обробку, зберігання та/або використання його персональних даних.</p>
              <p className='c-dark'>Звертаємо вашу увагу на те, що під час відвідування Сайту певні дані автоматично записуються на серверах, які використовує Фонд для системного адміністрування Сайту, зокрема: IP-адреса, з якої Користувач доступається до Сайту; тип браузера і операційна система; дата і час відвідування Сайту; переглянуті сторінки; і в разі переходу на Сайт за допомогою посилання з іншого сайту, адреса цього сайту.</p>
              <p className='c-dark'>Сторонні служби, такі як Google Analytics, можуть збирати статистику відвідувань Сайту, домени інтернет-провайдерів, країни походження відвідувачів, а також адреси веб-сайтів, які Користувач відвідав перед входом на Сайт і після залишення Сайту тощо. Такі сторонні служби мають власні політики конфіденційності щодо використання такої інформації.</p>
              <p className='c-dark'>Ми не збираємо інформацію про засоби платежу Користувача, такі як банківські карти чи подібну інформацію. У випадку здійснення благодійного внеску через Сайт, дані про платіж не надаються Фонду, а платіжному оператору/платіжній системі і регулюються їхньою Політикою конфіденційності. Ці платіжні системи дотримуються стандартів, встановлених PCI-DSS, який управляється Радою зі стандартів безпеки PCI. Вимоги PCI-DSS допомагають забезпечити безпечну обробку платіжної інформації.</p>

              <h4 className='c-dark py-3'>3. Використання інформації</h4>
              <p className='c-dark'>Персональна інформація та інша інформація, надана Користувачем, використовується тільки для тих цілей, для яких вона була надана Фонду. Залежно від суб'єкта персональних даних, який звертається до Фонду за допомогою Сайту, Фонд використовуватиме інформацію, надану Користувачем, для мети, зазначеної в Положенні.</p>

              <h4 className='c-dark py-3'>4. Безпека даних</h4>
              <p className='c-dark'>Ми зобов'язуємся ретельно зберігати інформацію, надану Користувачем, і вживати всіх заходів для захисту інформації від втрати, неправомірного використання або несанкціонованих змін. Передача інформації через Інтернет не завжди повністю безпечна, тому, як тільки ми отримаємо інформацію від вас, ми зробимо все можливе, щоб захистити ваші персональні дані.</p>
              <p className='c-dark'>Користувач підтверджує, що розуміє зазначені вище ризики, зокрема, що надана інформація може бути втрачена або отримана третіми сторонами, і що вони особисто та незалежно беруть на себе всі ризики, пов'язані з цим.</p>
              <p className='c-dark'>Персональні дані Користувача зберігаються протягом всього часу, необхідного для досягнення мети обробки персональних даних, але не більше 1095 календарних днів після закінчення звітного періоду, в якому такі персональні дані були надані Фонду Користувачем, якщо інший термін не визначений чинним законодавством України.</p>
              <p className='c-dark'>Ми не несемо відповідальності за упущену вигоду, незароблений прибуток, втрату даних або будь-які інші втрати Користувачів Сайту, якщо такі сталися під час використання Сайту.</p>

              <h4 className='c-dark py-3'>5. Розповсюдження інформації</h4>
              <p className='c-dark'>Ми можемо передавати інформацію, отриману від Користувача, третім сторонам тільки в обсязі, об'єктивно необхідному для досягнення мети, зазначеної в Положенні, або з метою захисту безпеки або цілісності сайту Фонду, за запитом наглядових, правоохоронних або судових органів, які проводяться відповідно до процедури, передбаченої чинним законодавством України, або в випадках, коли це необхідно для запобігання фізичній шкоді або фінансовим втратам.</p>
              <p className='c-dark'>На сайті можуть міститися посилання на інші сайти. Такі посилання надаються виключно з інформаційною метою і допомагають Фонду більш повно розкривати інформацію або ілюструвати опублікований матеріал.</p>
              <p className='c-dark'>Якщо у Користувача є будь-які питання або занепокоєння щодо цієї політики, він повинен звернутися до нас за адресою <a href="mailto:admin@vikonrob.co.ua">admin@vikonrob.co.ua</a></p>
              <p className='c-dark'>Ми залишаємо за собою право вносити зміни до Політики конфіденційності в будь-який час і з будь-якої причини, без додаткового повідомлення Користувачу про відповідні зміни. У разі змін до цієї Політики, повідомлення про такі зміни будуть розміщені на Сайті, і буде вказано дату набуття чинності цих змін.</p>
              <div className="s-100"></div>
            </div>
          </div>
        </div>
      <Footer />
    </>
  )
}

export { Privacy }
