import React from 'react'

const Services = () => {
  return (
    <section id='services'>
      <div className="s-100"></div>
      <div className="content">
        <div className="container tc">
          <h3 className="title-cta c-dark">ПОСЛУГИ</h3>
          <div className="s-20"></div>
          <div className="h4 title-intro-sub c-theme">СПЕЦІАЛІСТ ДЛЯ ВАШОГО ПРОЕКТУ</div>
          <div className="s-40"></div>
          <p className='c-dark title-sub mx-4'>Шукаєте спеціаліста, щоб підняти ваш поточний проект? Ви отримуєте нашого внутрішнього спеціаліста, який брав участь у інших проектах і готовий до нового виклику, щоб підняти вашу розробку на новий рівень. Зустрічайте нового, активного та вірного рок-зірку, яка візьме ваш проект на нові висоти.</p>
          <div className="s-40"></div>
          <div className="h4 title-intro-sub c-theme">СПЕЦІАЛІСТ ДЛЯ ВАШОЇ КОМАНДИ</div>
          <div className="s-40"></div>
          <p className='c-dark title-sub mx-4'>Потрібне ідеальне доповнення до вашої внутрішньої команди? Ви отримуєте доступ до нашого басейну професійних спеціалістів, який безпосередньо з'єднує вас з елітними талантами, адаптованими до ваших конкретних потреб. Забезпечте безшовну інтеграцію та експертні навички, переконавшись, що ваш новий член команди не просто відповідає, але й перевершує ваші очікування.</p>
          <div className="s-40"></div>
        </div>
      </div>
      <div className="s-60"></div>
    </section>
  )
}

export { Services }
