import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Home } from './pages/Home'
import { Privacy } from './pages/Privacy'
import { Cookie } from './pages/Cookie'
import { NotFound } from './pages/NotFound'

import './styles/custom.sass'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='privacy' element={<Privacy />} />
        <Route path='cookie' element={<Cookie />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export { App }
