import React from 'react'


const About = () => {
  return (
    <section id='about' className='bg-lite'>
      <div className="s-100"></div>
      <div className="content">
        <div className="container tc">
          <h3 className="title-cta c-theme">ПРО НАС</h3>
          <div className="s-40"></div>
          <p className='c-dark title-sub mx-4'>IT-Виконороб — це кадрова компанія з досвідом у розробці продуктів, де ми запускали проекти з нуля до процесу випуску. Весь цей досвід дав нам повне розуміння того, чого люди хочуть бачити у персоналі, якого вони наймають. І ми віримо, що правильне рішення щодо талантів — це не просто відповідність вимог клієнта з деякими словами в резюме, це про ретельний пошук і забезпечення обох: бажаної роботи для кандидата та потрібного спеціаліста для клієнта.</p>
          <div className="s-40"></div>
        </div>
      </div>
      <div className="s-60"></div>
    </section>
  )
}

export { About }
