import React, { useState, useEffect } from 'react'
import logo from '../assets/logo-full.png'

const Nav = () => {
  const [opened, setOpened] = useState(true)

  useEffect(() => {
    if (!opened) {
      document.body.classList.add('menu-active')
    } else {
      document.body.classList.remove('menu-active')
    }
  }, [opened])

  const toggleMenu = () => {
    setOpened(!opened)
  }

  return (
    <header>
      <div className='container'>
        <nav>
          <h1 id='logo' className='flex aic'>
            <a href='/' aria-label='Logotype'>
              <img src={logo} width={238} height={112} alt='IT-Виконороб' />
            </a>
          </h1>
          <div className={opened ? 'burger' : 'burger open'} onClick={toggleMenu}>
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href='#about' className='mx-3 px-1'>
              ПРО НАС
            </a>
            <a href='#services' className='mx-3 px-1' onClick={toggleMenu}>
              ПОСЛУГИ
            </a>
            <a href='#contact' className='mx-3 px-1' onClick={toggleMenu}>
              КОНТАКТИ
            </a>
          </div>
        </nav>
      </div>
    </header>
  )
}

export { Nav }
