import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className='py-3'>
        <div className='container'>
          <div className='s-20'></div>
          <div className='row'>
            <div className='col-lg-6 py-3'>
              <a href='/' className='c-medium title-mini'>
                ©2024 - IT-Виконороб. Всі права захищено.
              </a>
            </div>
            <div className='col-lg-6 c-medium title-mini tr py-3'>
              <a href='/cookie' className='mx-3'>
                Політика використання файлів cookie
              </a>
              <a href='/privacy' className='mx-3'>
                Політика конфіденційності
              </a>
            </div>
          </div>
          <div className='s-20'></div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
