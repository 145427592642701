import React from 'react'
import { Nav } from '../components/Nav'
import { Intro } from './parts/Intro'
import { About } from './parts/About'
import { Services } from './parts/Services'
import { Contact } from './parts/Contact'
import { Footer } from '../components/Footer'

const Home = () => {

  return (
    <>
      <Nav />
      <Intro />
      <About />
      <Services />
      <Contact />
      <Footer />
    </>
  )
}

export { Home }
