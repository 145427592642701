import React from 'react';
import { BiLogoTelegram, BiSolidPhone, BiEnvelope } from 'react-icons/bi';

const dark = '#282828';

const Contact = () => {
  return (
    <section id='contact' className='bg-lite'>
      <div className='s-100'></div>
      <div className='content'>
        <div className='container tc'>
          <div className='s-100'></div>
          <h3 className='title-cta c-theme'>ЗВ'ЯЖІТЬСЯ З НАМИ</h3>
          <div className='s-50'></div>
          <div className='flex jcc aic'>
            <a href='https://telegram.me/@maska6955'>
              <BiLogoTelegram size={56} color={dark} />
              <div className='s-10'></div>
              <p className='title-mini c-dark'>@maska6955</p>
            </a>
            <div className='w-10 h-10'></div>
            <a href='tel:+380503240484'>
              <BiSolidPhone size={56} color={dark} />
              <div className='s-10'></div>
              <p className='title-mini c-dark'>+38 050 324 0484</p>
            </a>
            <div className='w-10 h-10'></div>
            <a href='mailto:maska6955@gmail.com'>
              <BiEnvelope size={56} color={dark} />
              <div className='s-10'></div>
              <p className='title-mini c-dark'>maska6955@gmail.com</p>
            </a>
          </div>
          <div className='s-50'></div>
          <div className='s-100'></div>
        </div>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Contact };
